<template>
  <div>
    <!-- Header Alert -->
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">Daftar <b>Bahan</b></div>
      <b-button
        squared
        @click="$router.push('/lab-materials/add')"
        v-if="btnAccess"
        variant="primary"
        >Tambah</b-button
      >
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div>
              <!-- Filter -->
              <div class="row justify-content-end mt-n3">
                <div class="col-md-3">
                  <treeselect
                    v-model="filter.lab_material_category_id"
                    :multiple="false"
                    placeholder="Saring Kategori Bahan"
                    :options="lab_material_categories"
                    @input="filterByLabMaterialCategory"
                  />
                </div>
                <div class="col-md-5">
                  <b-input-group>
                    <template #append>
                      <b-button
                        variant="success"
                        squared
                        size="sm"
                        @click="search"
                      >
                        Cari
                      </b-button>
                      <b-button
                        variant="danger"
                        squared
                        size="sm"
                        @click="reset"
                      >
                        Reset
                      </b-button>
                    </template>
                    <b-form-input
                      type="text"
                      v-model="filter.name"
                      placeholder="Cari Nama/Part/No. Produk/Merk"
                    ></b-form-input>
                  </b-input-group>
                </div>
              </div>
              <div class="row justify-content-end">
                <b-button
                  class="mr-1"
                  style="background-color: white; border-color: #107c41"
                  v-b-tooltip.hover
                  title="Download Excel"
                  @click="$bvModal.show('modal-import-excel')"
                >
                  <i class="fas fa-file-excel px-0" style="color: #107c41"></i>
                  Import Excel
                </b-button>
                <vue-excel-xlsx
                  :data="itemsNoPagination"
                  :columns="columns"
                  :filename="`Daftar Bahan`"
                  :sheetname="`Daftar Bahan`"
                >
                  <b-button
                    class="mr-1"
                    style="background-color: #107c41"
                    v-b-tooltip.hover
                    title="Download Excel"
                  >
                    <i class="fas fa-file-excel text-white px-0"></i>
                  </b-button>
                </vue-excel-xlsx>
              </div>
              <b-table
                striped
                hover
                :items="items"
                :fields="fields"
                class="mt-3"
                responsive
              >
                <template #table-colgroup="scope">
                  <col
                    v-for="field in scope.fields"
                    :key="field.key"
                    :style="{ width: field.key === 'action' ? '15%' : '' }"
                  />
                </template>

                <template #cell(photo)="data">
                  <div
                    class="
                      d-flex
                      justify-content-start
                      align-items-center
                      image-container
                    "
                  >
                    <div class="image-input-wrapper" style="position: relative">
                      <img class="image" :src="data.item.photo_url" />
                    </div>
                  </div>
                </template>

                <template #cell(name)="data">
                  <span
                    >{{ data.item.name != "" ? data.item.name : "-" }} /
                    {{
                      data.item.lab_material_category_name != ""
                        ? data.item.lab_material_category_name
                        : "-"
                    }}</span
                  >
                </template>

                <template #cell(brand)="data">
                  <span
                    >{{ data.item.brand == "" ? "-" : data.item.brand }} /
                    {{
                      data.item.serial_number == ""
                        ? "-"
                        : data.item.serial_number
                    }}
                    /
                    {{
                      data.item.part_number == "" ? "-" : data.item.part_number
                    }}</span
                  >
                </template>

                <template #cell(location)="data">
                  <span
                    >{{ data.item.location == "" ? "-" : data.item.location }} /
                    {{ data.item.risk == "" ? "-" : data.item.risk }}</span
                  >
                </template>

                <template #cell(action)="data">
                  <b-button
                    size="sm"
                    class="mr-1 btn-circle btn-outline-info"
                    v-b-tooltip.hover
                    title="Detail"
                    @click="
                      $router.push({
                        path: '/lab-materials/detail/' + data.item.id,
                      })
                    "
                    ><i class="fas fa-eye px-0"></i
                  ></b-button>
                  <b-button
                    size="sm"
                    class="mr-1 btn-circle btn-outline-success"
                    v-b-tooltip.hover
                    title="Edit"
                    @click="
                      $router.push({
                        path: '/lab-materials/edit/' + data.item.id,
                      })
                    "
                    v-if="btnAccess"
                    ><i class="fas fa-edit px-0"></i
                  ></b-button>
                  <b-button
                    size="sm"
                    class="btn-circle btn-outline-danger"
                    v-b-tooltip.hover
                    title="Hapus"
                    @click="deleteData(data.item.id)"
                    v-if="
                      data.item.lab_material_stocks == null && btnAccess == true
                    "
                    ><i class="fas fa-trash px-0"></i
                  ></b-button>
                </template>
              </b-table>

              <b-pagination
                v-if="items.length != 0"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                @page-click="pageOnClick"
                class="mt-4"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Import -->
    <b-modal id="modal-import-excel" size="lg" @ok="importTool">
      <template #modal-title>Import Bahan</template>
      <vue-excel-xlsx
        :data="itemImport"
        :columns="templateImport"
        :filename="`Template Impor Bahan`"
        :sheetname="`Template Impor Bahan`"
      >
        <b-button
          class="mr-1"
          style="background-color: #107c41"
          v-b-tooltip.hover
          title="Download Excel"
        >
          <i class="fas fa-file-excel text-white px-0"></i>
          <span class="text-white ml-2">Download template</span>
        </b-button>
      </vue-excel-xlsx>

      <div class="row my-2">
        <div class="col-12">
          <fieldset style="border: 1px solid #dee2e6; padding: 8px">
            <legend
              style="
                border: 1px solid #dee2e6;
                padding: 9px;
                margin-left: 13px;
                font-size: initial;
                width: initial;
                background: white;
              "
            >
              Syarat File Excel
            </legend>
            <ul class="ml-4">
              <li>
                File yang akan diimport harus sesuai dengan template yang bisa
                didownload diatas ini.
              </li>
              <li>
                Kolom tgl_kadaluarsa dan pada file template berformat
                YYYY-MM-DD(0000-00-00)
              </li>
              <li>
                Kolom yang wajib diisi hanya nama, kategori, dan resiko
              </li>
              <li>
                Kolom resiko hanya berisi dua pilihan (Toxic/Non Toxic)
              </li>
            </ul>
          </fieldset>
        </div>
      </div>

      <b-form-group id="input-group-image">
        <label for="input-image">Import Bahan: </label>
        <b-form-file
          v-model="formImport.file"
          placeholder="Import Bahan"
          drop-placeholder="Lepaskan file di sini..."
          accept=".csv, .xls, .xlsx"
          @change="onFileUpload"
          ref="parental"
        ></b-form-file>
        <small class="text-danger">{{ errorImport.file }}</small>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";

export default {
  data() {
    return {
      // filter
      filter: {
        name: "",
        tool_status_id: "",
        lab_material_category_id: "",
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      fields: [
        {
          key: "number",
          label: "No",
          sortable: true,
        },
        {
          key: "photo",
          label: "Foto",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama/Kategori",
          sortable: true,
        },
        {
          key: "brand",
          label: "Merk/No. Seri/No. Produk",
          sortable: true,
        },
        {
          key: "location",
          label: "Penyimpanan/Resiko",
          sortable: true,
        },
        {
          key: "remaining_stock",
          label: "Sisa Stok",
          sortable: true,
        },
        {
          key: "expired_date_display",
          label: "Tgl. Kadaluarsa",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          tdClass: "nowrap",
        },
      ],
      columns: [
        {
          field: "name",
          label: "Nama",
        },
        {
          field: "lab_material_category_name",
          label: "Kategori",
        },
        {
          field: "brand",
          label: "Merk",
        },
        {
          field: "serial_number",
          label: "No. Serial",
        },
        {
          field: "part_number",
          label: "Part Number",
        },
        {
          field: "location",
          label: "Lokasi Penyimpanan",
        },
        {
          field: "risk",
          label: "Resiko",
        },
        {
          field: "remaining_stock",
          label: "Sisa Stok",
        },
        {
          field: "expired_date_display",
          label: "Tanggal Kadaluarsa",
        },
        {
          field: "fund_resource",
          label: "Sumber Dana Pembelian",
        },
      ],
      items: [],
      itemsNoPagination: [],
      client_types: [],
      tool_statuses: [],
      lab_material_categories: [],
      templateImport: [
        {
          field: "nama",
          label: "nama",
        },
        {
          field: "kategori",
          label: "kategori",
        },
        {
          field: "deskripsi",
          label: "deskripsi",
        },
        {
          field: "noserial",
          label: "noserial",
        },
        {
          field: "partnumber",
          label: "partnumber",
        },
        {
          field: "sop",
          label: "sop",
        },
        {
          field: "merek",
          label: "merek",
        },
        {
          field: "lokasi",
          label: "lokasi",
        },
        {
          field: "resiko",
          label: "resiko",
        },
        {
          field: "tgl_kadaluarsa",
          label: "tgl_kadaluarsa",
        },
      ],
      itemImport: [{ nama: "", spesifikasi: "" }],
      //formStatus
      formStatus: {
        tool_status_id: 0,
        id: 0,
      },
      // formImport
      formImport: {},
      errorImport: {
        file: "",
      },
      // access
      btnAccess: false,
    };
  },
  methods: {
    pageOnClick(evt, page) {
      evt.preventDefault();
      //("evt", evt);
      //("page", page);
      this.currentPage = page;
      this.pagination();
    },

    async pagination() {
      let filterParams = `&name=${this.filter.name}&lab_material_category_id=${this.filter.lab_material_category_id}`;
      let response = await module.paginate(
        "api/lab-materials",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let responseNoPagination = await module.paginate(
        "api/lab-materials-no-pagination",
        `?${filterParams}`
      );
      this.itemsNoPagination = responseNoPagination.data;
      console.log("responseNoPagination", responseNoPagination);

      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.items = response.data;
    },

    async getToolCategoryOption() {
      let response = await module.setTreeSelect("api/lab-material-categories");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.lab_material_categories = response.data;
        this.lab_material_categories.unshift({
          label: "Saring Kategori Bahan",
          id: "",
          isDisabled: true,
        });
      }
    },

    filterByLabMaterialCategory(evt) {
      if (typeof evt == "undefined") {
        this.filter.lab_material_category_id = "";
      }
      this.pagination();
    },

    search() {
      this.pagination();
    },
    reset() {
      this.filter.name = "";
      this.filter.lab_material_category_id = "";
      this.pagination();
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete("api/lab-materials/" + id);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    onFileUpload(evt) {
      this.formImport.file = evt.target.files[0];
      console.log("formImport", this.formImport);
    },

    async importTool() {
      let formData = new FormData();
      for (const [key, value] of Object.entries(this.formImport)) {
        formData.append(key, value);
      }

      let response = await module.submit(formData, `api/lab-materials/import`);
      if (response.state == "error") {
        Swal.fire({
          title: "Gagal",
          text: "Gagal import alat",
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.$bvModal.hide("modal-import-excel");
      } else {
        // Success
        Swal.fire({
          title: response.success.title,
          text: response.success.message,
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.$bvModal.hide("modal-import-excel");
        this.pagination();
      }
    },

    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "1101") {
          this.btnAccess = true;
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Bahan", route: "/lab-materials" },
    ]);
    this.pagination();
    this.getToolCategoryOption();
    this.checkAccess();
  },
};
</script>

<style>
.nowrap {
  white-space: nowrap;
}

.image-input-wrapper {
  width: 50px !important;
  height: 50px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.sr-only {
  width: 0px !important;
}
</style>
